<template>
    <div>
        <headTitle/>
        <img class="mobile-example-headImg" src="../../assets/image/mobile/05-1新闻资讯.png"/>
        <div class="mobile-head-explain">
            <div class="mobile-head-explain-title">新闻资讯</div>
        </div>
        <div class="mobile-content-box1" style="margin-bottom:5rem">
            <div v-for="item in box4List" :key="item.url">
                <div class="mobile-carousel-4" style="background-color: rgb(255, 255, 255);border: 1px solid #EDEDED;">
                    <img :src="item.url" :key="item.url">
                    <div class="box4">
                        <div class="title-box">
                           <div class="title">{{item.title}}</div>
                           <div class="date">{{item.date}}</div>
                        </div>

                        <div class="more" @click="jumpMobileRoute(item.route)">阅读更多<i class="el-icon-right" style="margin-top:0.8rem"></i></div>
                    </div>
                </div>
            </div>
        </div>
        <mobileFoot/>
    </div>
</template>

<script>
import headTitle from '../../components/mobile/head-title.vue'
import mobileFoot from '../../components/mobile/mobile-foot.vue'
import mobileCarousel from '../../components/mobile/mobile-carousel.vue'
import { getAction } from '@/utils/request.js';
import Swiper from "swiper";
export default {
    name: 'BizfocusIndexMobileIndex',
    components:{headTitle,mobileCarousel,mobileFoot},
    data() {
        return {
            box4List:[

            ],
        };
    },
    async created() {
        // this.box4List.reverse()
        await this.init()
        console.log('box4List',this.box4List)
    },
    mounted() {
    },

    methods: {
        async init(){
            const res = await getAction(`/wp/wp-json/wp/v2/posts?categories=6&per_page=100`);
            if(res){
                res.forEach(async(element,index) => {
                    let oneNew = {}
                    oneNew.index = index;
                    oneNew.title = element.title.rendered
                    let date = element.date.split('T')
                    let newDate = date[0].split('-')[0] + '/' + date[0].split('-')[1] + '/' + date[0].split('-')[2]
                    oneNew.date = newDate
                    oneNew.content = element.content.rendered.split('<p>')[1].split('</p>')[0]
                    oneNew.route = `/mobile-news-detail/${element.id}`
                    try{
                      const res2 =  await getAction(`/wp/wp-json/wp/v2/media/${element.featured_media}`)
                      oneNew.url = res2.guid.rendered.replace("http://69.230.196.6:8088", "https://www.bizfocus.cn/wp")
                    }catch(e){
                      oneNew.url = ''
                    }
                    this.box4List.push(oneNew)
                });
                setTimeout(()=>{
                   this.box4List.sort((function(a,b) {
                        return a.index-b.index
                    }))
                },500)
            }
        },
        jumpMobileRoute(route){
            this.$router.push(route)
            console.log(route)
        }
    },
};
</script>

<style lang="less" scoped>
.mobile-content-box1{
    width: 100%;
    margin: auto;
    margin-top: 3rem;
}
</style>
